import React from "react";
import {
  IconDownload,
  IconFileDownload,
  IconPhoneIncoming,
} from "@tabler/icons-react";

import { InteractiveData } from "../../../interfaces";
import { FormattedMessage } from "react-intl";

const getFilename = (url: string) => {
  try {
    const urlNoQuery = url.split("?")[0];
    const filename = urlNoQuery.substring(urlNoQuery.lastIndexOf("/") + 1);
    return filename;
  } catch (err) {
    return "File";
  }
};

function InteractiveContent({
  content,
  mode = "desktop",
  isSentMessage,
  scrollToBottom,
  openImage,
}: {
  content: InteractiveData;
  mode?: String;
  isSentMessage: boolean;
  scrollToBottom?: (value: number) => void;
  openImage?: (link: string) => void;
}): JSX.Element {
  const component: { [key: string]: React.ReactNode | JSX.Element } =
    React.useMemo(
      () => ({
        audio: content.header?.audio?.url ? (
          <div className="interactive-audio">
            <audio controls>
              <source
                src={`https://visito-media.sfo2.digitaloceanspaces.com/${content.header.audio.url}`}
              />
              Your browser does not support the audio element.
            </audio>
          </div>
        ) : (
          <span>voice note</span>
        ),
        document: (
          <div className="interactive-document flex flex-column gap-1 mb-1">
            <iframe
              src={`https://visito-media.sfo2.digitaloceanspaces.com/${content.header?.document?.url}`}
              title="Document file"
            />
            <a
              className={`download-btn ${isSentMessage ? "left" : "right"}`}
              href={`https://visito-media.sfo2.digitaloceanspaces.com/${content.header?.document?.url}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconDownload />
            </a>
          </div>
        ),
        file: (
          <div className="interactive-file flex flex-column gap-1 mb-1">
            <a
              href={content.header?.file?.url}
              target="_blank"
              rel="noreferrer"
              className="flex align-items-center"
            >
              <IconFileDownload size={28} />
              <span>{getFilename(content?.header?.file?.url)}</span>
            </a>
          </div>
        ),
        video: (
          <div className="interactive-video">
            <video
              onLoadStart={() => {
                scrollToBottom?.(0);
              }}
              autoPlay={true}
              muted={true}
            >
              <source
                src={`https://visito-media.sfo2.digitaloceanspaces.com/${content.header?.video?.url}`}
                type="video/mp4"
              />
            </video>
          </div>
        ),
        image: (
          <div
            className="interactive-img"
            onClick={() =>
              openImage?.(
                content.header?.image?.url?.includes("https://")
                  ? content.header?.image?.url
                  : `https://visito-media.sfo2.digitaloceanspaces.com/${content.header?.image?.url}`
              )
            }
          >
            <img
              alt=""
              src={
                content.header?.image?.url?.includes("https://")
                  ? content.header?.image?.url
                  : `https://visito-media.sfo2.digitaloceanspaces.com/${content.header?.image?.url}`
              }
              onLoad={() => scrollToBottom?.(0)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://placehold.co/200x200?text=Content+no+longer+available";
              }}
            />
          </div>
        ),
        call: (
          <div className="interactive-call">
            <IconPhoneIncoming size={22} />
            {content.header?.audio?.url ? (
              <audio controls>
                <source
                  src={`https://visito-media.sfo2.digitaloceanspaces.com/${content.header?.audio?.url}`}
                />
                Your browser does not support the audio element.
              </audio>
            ) : null}
          </div>
        ),
      }),
      [content.header, isSentMessage]
    );

  return (component[content.header.type] as JSX.Element) || <></>;
}

export default InteractiveContent;
