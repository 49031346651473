import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useIonToast, IonSpinner, IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonAlert } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";
import ProgressBar from "react-bootstrap/ProgressBar";
import { IconCircleCheckFilled, IconX } from "@tabler/icons-react";

import CrawlingApi from "../../../api/crawling";
import CrawlingCompleted from "../../common/crawling-completed";
import CrawlingSvg from "../../../theme/images/crawling.svg";

import styles from "../styles.module.css";
import crawlingStyles from "./styles.module.css";
import { UrlWithStatus, useEmbeddingsActions, useEmbeddingsData } from "../../../store/embeddings";
import { useSelector } from "react-redux";
import { makeSelectUser } from "../../../selectors";

const Url = ({ url }: { url: UrlWithStatus }) => (
  <div className="flex align-items-center justify-content-between gap-2">
    <a href={url.url} target="_blank" rel="noreferrer" className="fs-3 break-all">
      {url.url}
    </a>
    <div>
      {url.completed ? (
        <IconCircleCheckFilled style={{ color: "var(--visito-color-1)" }} size={23} />
      ) : (
        <IonSpinner name="circular" style={{ color: "var(--visito-color-1)", width: "21px", height: "21px" }} />
      )}
    </div>
  </div>
);

export default function CrawlWebsite({ variant = "fixed" }: { variant?: "full" | "fixed" }) {
  const [website, setWebsite] = React.useState<string>()
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [present] = useIonToast();

  const { company } = useSelector(makeSelectUser());

  const { embedding } = useEmbeddingsData();
  const { setEmbedding } = useEmbeddingsActions();
  const { urls, progress, isOptimizing, isScanning } = embedding;

  const intl = useIntl();
  const queryClient = useQueryClient();

  const { mutate: mutateNewCrawling, isPending: isCreatingEvent } =
    useMutation({
      mutationFn: CrawlingApi.newCrawlingEvent,
      onSuccess: ({ data }) => {
        if (data && data?.ok) {
          return setEmbedding({ isScanning: true })
        }
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    })

    const { mutate: mutateSkipCrawling, isPending: isSkipping } =
    useMutation({
      mutationFn: CrawlingApi.skipCrawling,
      onSuccess: async ({ data }) => {
        if (data && data?.ok) {
          setOpenAlert(false);
          await queryClient.invalidateQueries({
            queryKey: ["getAiSettings"],
          });
        }
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    })

  const onBeforeSkipCrawling = () => {
    return mutateSkipCrawling({
      companyId: company.id
    })
  }

  const onSubmitCrawling = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (website) {
      mutateNewCrawling({ companyId: company.id, url: website });
    }
  };

  const onHideCrawlingCompleted = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["getAiSettings"],
    });
    return setEmbedding({ progress: 0 });
  };

  React.useEffect(() => {
    if (!website && website !== "" && company?.website) {
      setWebsite(company.website)
    }
  }, [website, company?.website]);

  const needsCrawling = !urls || urls.length <= 0;
  const isRequesting = isCreatingEvent || isScanning;

  return (
    <div
      className={`${crawlingStyles.crawlWebisteContainer} ${!needsCrawling ? "pt-5 pb-4 max-h-full overflow-y-scroll" : ""} flex flex-column align-items-center gap-5 w-100 h-100 ${variant === "full" ? "no-radius no-border" : ""}`}
    >
      {needsCrawling && (
        <div className={crawlingStyles.skipCrawling}>
          <IconX
            size={22}
            role="button"
            onClick={(e) => {
              e.preventDefault();
              setOpenAlert(true)
            }}
          />
          <IonAlert
            isOpen={openAlert}
            header={intl.formatMessage({ id: "aiCrawlingSkip" })}
            message={intl.formatMessage({ id: "aiCrawlingSkipDesc" })}
            buttons={[
              {
                text: intl.formatMessage({ id: "common.cancel" }),
                role: "cancel",
                handler: () => setOpenAlert(false),
                cssClass: `alertCancel ${isSkipping ? "disabled" : ""}`,
              },
              {
                text: intl.formatMessage({ id: "common.confirmContinue" }),
                role: "confirm",
                handler: onBeforeSkipCrawling,
                cssClass: `alertConfirm ${isSkipping ? "disabled" : ""}`,
              },
            ]}
            onDidDismiss={() => setOpenAlert(false)}
          />
        </div>
      )}
      <h2 className="fs-6">
        <FormattedMessage id={needsCrawling ? "aiCrawl" : "aiCrawling"} />
      </h2>
      {needsCrawling ? (
        <form onSubmit={onSubmitCrawling} className={`flex flex-row gap-2 ${variant === "full" ? "w-90" : "w-60"}`}>
          <input
            type="text"
            className="form-control"
            value={website || ""}
            onChange={(e) => setWebsite(e.target.value)}
            disabled={isRequesting}
            autoComplete="off"
            required
          />
          <button
            className="btn btn-visito-primary btn-sm w-25"
            disabled={isRequesting}
            type="submit"
          >
            {isRequesting ? (
              <IonSpinner name="circular" style={{ color: "var(--ion-color-light)", width: "19px", height: "19px" }} />
            ) : (
              <FormattedMessage id="common.scan" />
            )}
          </button>
        </form>
      ) : (
        <div className={`flex flex-column gap-5 ${variant === "full" ? "w-90" : "w-50"}`}>
          <div className={styles.loaderWrapper}>
            <div className={`${styles.loaderContainer} mt-0`}>
              <div className="mb-3">
                <img alt="" src={CrawlingSvg} height="155px" />
              </div>
              <div className="color-step-900 font-14 fw-semibold-1">
                <FormattedMessage id={isOptimizing ? "aiSettingsOptimizing" : "aiSettingsWorking"} />
              </div>
              <div className="mt-2">
                <ProgressBar
                  now={progress}
                  className="visito-progress-bar"
                />
              </div>
            </div>
          </div>
          <div className="row row-gap-3">
            {!isOptimizing && progress !== 100 && urls && urls.map((u) => (
              <div className="col-md-12 pb-1" key={u.url}>
                <Url url={u} />
              </div>
            ))}
            {(isOptimizing || progress === 100) && (
              <div className="col-md-12 pb-1">
                 <div className="flex align-items-center justify-content-between gap-2 mb-4 px-3">
                    <FormattedMessage id="aiSettingsOptimizingDesc" />
                    {progress === 100 ? (
                      <IconCircleCheckFilled style={{ color: "var(--visito-color-1)" }} size={21} />
                    ) : (
                      <IonSpinner name="circular" style={{ color: "var(--visito-color-1)", width: "20px", height: "20px" }} />
                    )}
                  </div>
                <IonAccordionGroup>
                  <IonAccordion value="first">
                    <IonItem slot="header" color="light">
                      <IonLabel slot="start">
                        <div className="flex align-items-center gap-2">
                          <FormattedMessage id="aiCrawlingWebsiteSucc" />
                          <IconCircleCheckFilled style={{ color: "var(--visito-color-1)" }} size={21} />
                        </div>
                      </IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                      <div className="flex flex-column gap-1 py-2 px-3">
                        {urls && urls.map((u) => (
                          <Url url={u} key={u.url} />
                        ))}
                      </div>
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
              </div>
            )}
          </div>
        </div>
      )}
      {progress === 100 && (
        <CrawlingCompleted onOk={onHideCrawlingCompleted} />
      )} 
    </div>
  )
}
