import { createAction } from "@reduxjs/toolkit";
import { CallEvent, CallServerEvent } from "../interfaces";

export const setCallEvent = createAction(
  "SET_CALL_EVENT",
  function prepare(event: CallEvent) {
    return {
      payload: { event },
    };
  }
);

export const setCallServerEvent = createAction(
  "SET_CALL_SERVER_EVENT",
  function prepare(event: CallServerEvent) {
    return {
      payload: { event },
    };
  }
);
