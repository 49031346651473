import { connect } from "react-redux";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonList,
  IonSelectOption,
  IonBackButton,
  IonSelect,
  IonButtons,
} from "@ionic/react";

import { FormattedMessage, useIntl } from "react-intl";

//Inferfaces
import { User } from "../../../interfaces";
// API
import Auth from "../../../api/auth";
// Actions
import { setLanguage } from "../../../actions/app";

const Language: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { language } = props.user;

  const switchLanguage = async (language: string) => {
    const { err } = await Auth.language({ language });
    if (err) {
      console.log(err);
    }
    props.dispatch(setLanguage(language));
  };
  const intl = useIntl();
  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/settings`}
              text={intl.formatMessage({ id: "common.back" })}
            />
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="language.title" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <div className="view-wrapper">
          <div className="view-pane-title mt-4">
            <FormattedMessage id="language.title" />
          </div>
          <IonList inset={true}>
            <IonItem>
              <IonSelect
                label={intl.formatMessage({ id: "language.selectorLabel" })}
                placeholder="Make a Selection"
                value={language}
                onIonChange={(e) => switchLanguage(e.target.value)}
              >
                <IonSelectOption value="es">
                  <FormattedMessage id="language.spanish" />
                </IonSelectOption>
                <IonSelectOption value="en">
                  <FormattedMessage id="language.english" />
                </IonSelectOption>
                <IonSelectOption value="pt">
                  <FormattedMessage id="language.portuguese" />
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  user: User;
}

export default connect((props: any) => ({}))(Language);
