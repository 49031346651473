import { createReducer } from "@reduxjs/toolkit";
import { setCallEvent, setCallServerEvent } from "../actions/call";

import { CallEvent, CallServerEvent } from "../interfaces";

const callEvent: CallEvent = {
  contactId: "",
};

const callServerEvent: CallServerEvent = {
  event: null,
};

export const CallEvents = createReducer(
  { callEvent, callServerEvent },
  {
    [setCallEvent.type]: (state, action) => ({
      ...state,
      callEvent: action.payload.event,
    }),
    [setCallServerEvent.type]: (state, action) => ({
      ...state,
      callServerEvent: action.payload.event,
    }),
  }
);
