import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonModal,
  IonButtons,
  IonButton,
} from "@ionic/react";

import styles from "../styles.module.css";
import { Integration } from "../../../interfaces";

interface Properties {
  isOpen: boolean;
  onWillDismiss: () => void;
  loading: boolean;
  error: string;
  integration: Integration;
  onCreate: Function;
  isMobile: boolean;
}

const NewDbConnection = ({
  isOpen,
  onWillDismiss,
  onCreate,
  loading,
  error,
  integration,
  isMobile,
}: Properties) => {
  const intl = useIntl();

  const [formData, setFormData] = useState<{
    name: string;
    propertyId: string;
    widgetId: string;
    publisher: string;
    currency: string;
    countryCode: string;
    bookingEngine: string;
    timezone: string;
    phoneNumber: number | null;
  }>({
    name: "",
    propertyId: "",
    widgetId: "",
    publisher: "channelmanager",
    currency: "MXN",
    countryCode: "MX",
    bookingEngine: "",
    timezone: "America/Mexico_City",
    phoneNumber: null,
  });

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("hola");
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { value, name } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const { cssLogo, key, fields } = integration;
  return (
    <IonModal isOpen={isOpen} onWillDismiss={onWillDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {isMobile ? (
              <FormattedMessage id={`integrations.${key}Name`} />
            ) : (
              <div className={styles.titleDisplay}>
                <div className={`${styles.titleItem} ${styles.logo}`}>
                  <div className={`${styles.logo} ${cssLogo}`}></div>
                </div>

                <h3 className="fs-3">
                  <FormattedMessage id={`integrations.${key}Name`} />
                </h3>
              </div>
            )}
          </IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={() => onWillDismiss()}>
              <FormattedMessage id="common.cancel" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={styles.modalContent}>
          <h3 className="fs-3">
            <FormattedMessage id={`integrations.${key}Add`} />
          </h3>

          <form onSubmit={handleForm} className={styles.dbForm}>
            {fields.map(({ value, key, type, options }) => {
              switch (type) {
                case "text":
                case "number":
                  return (
                    <div className={styles.inputHolder} key={key}>
                      <label htmlFor={key}>
                        <FormattedMessage id={`integrations.${key}Db`} />
                      </label>
                      <input
                        type={`${type}`}
                        id={key}
                        name={key}
                        placeholder={intl.formatMessage({
                          id: `integrations.${key}DbPlaceholder`,
                        })}
                        value={(formData as any)[key]}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                  );
                case "option":
                  return (
                    <div className={styles.inputHolder} key={key}>
                      <label htmlFor={key}>
                        <FormattedMessage id={`integrations.${key}Db`} />
                      </label>
                      <Form.Select
                        aria-label="Select"
                        name={key}
                        onChange={(e) => handleChange(e)}
                        value={(formData as any)[key]}
                      >
                        {options?.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  );
              }
            })}
            <button
              className="btn btn-visito-primary btn-sm mt-3"
              color="success"
              onClick={() => onCreate(formData)}
              disabled={loading}
              type="submit"
            >
              {loading ? (
                <>
                  <FormattedMessage id="common.loading" />
                  {`...`}
                </>
              ) : (
                <FormattedMessage id="common.continue" />
              )}
            </button>
          </form>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default NewDbConnection;
