import { useState } from "react";
import { connect } from "react-redux";
import { Location } from "history";
import { Link } from "react-router-dom";
import SkeletonText from "../../components/SkeletonText";

import { IonPage, IonContent, IonText, useIonViewDidEnter } from "@ionic/react";
import { FormattedMessage } from "react-intl";
import Spinner from "react-bootstrap/Spinner";

import styles from "./styles.module.css";
import "./login.css";

import Auth from "../../api/auth";
import Integrations from "../../api/integrations";
import { setAuth } from "../../utils/localStorage";
import serialize, { deserialize } from "../../utils/serialize";
import { setAppUser, setAppAuth } from "../../actions/app";

interface RegisterForm {
  email: string;
  password: string;
  website: string;
  companyCode: string;
}

interface Properties {
  name: string;
}

interface Company {
  code: string;
  locations: Properties[];
}

interface Error {
  message: string;
}

const Register: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [show, setShow] = useState(false);
  const [logInError, setLogInError] = useState(null);
  const [CBError, setCBError] = useState("");
  const [CBSuccess, setCBSuccess] = useState<Company>({
    code: "",
    locations: [],
  });
  const [loading, setLoading] = useState(false);
  const [CBLoading, setCBLoading] = useState(false);
  const [registerLink, setRegisterLInk] = useState("");
  const [formData, setFormData] = useState<RegisterForm>({
    email: "",
    password: "",
    website: "",
    companyCode: "",
  });

  useIonViewDidEnter(() => {
    const { search } = props.location;
    const query = deserialize(search);

    if (query.code) {
      requestCBInfo(query.code);
    } else {
      setCBError("Please try to connect again from cloudbeds dashboard");
    }
    const queryString = serialize(query);
    setRegisterLInk(`/auth/login?pathname=/cloudbeds&${queryString}`);
  });

  const requestCBInfo = async (code: string) => {
    setCBLoading(true);
    const { data, err } = await Integrations.cloudbedsPreviewCode(code);
    if (err) {
      setCBError(err.message || "Something went wrong");
    }
    if (data) {
      setCBSuccess({
        code: data.company.code,
        locations: data.company.locations,
      });
    }
    setCBLoading(false);
  };

  const registerApi = async () => {
    formData["companyCode"] = CBSuccess.code;
    await setLoading(true);
    const { data, err } = await Auth.register(formData);
    if (data) {
      const { token, refreshToken, user } = data;
      const { email, company, language, companies } = user;
      await setAuth({ refreshToken, token, email, company });
      await props.dispatch(setAppAuth({ refreshToken, token }));
      await props.dispatch(setAppUser({ email, company, language, companies }));
    }
    if (err) {
      let message = err.message;
      if (err.details && err.details.body && err.details.body.length) {
        err.details.body.forEach((e: Error) => {
          message = message + " " + e.message;
        });
      }
      await setLogInError(message);
    }
    await setLoading(false);
  };

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    registerApi();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const form = (
    <>
      <div className="visito-card visito-block mb-4 mt-4">
        <div className="visito-card-body bg-suttle">
          <div className="color-step-700">
            {CBSuccess.locations.map((p, k) => (
              <div key={k}>{p.name}</div>
            ))}
          </div>
        </div>
      </div>
      <form className={styles.authForm} onSubmit={handleForm}>
        <div className={styles.inputHolder}>
          <label>
            <FormattedMessage id="auth.register.web" />
          </label>
          <input
            type="text"
            id="website"
            value={formData.website}
            onChange={(e) => handleChange(e)}
            className="form-control"
            placeholder="https://example.com"
            required
          />
        </div>
        <div className={styles.inputHolder}>
          <label>
            <FormattedMessage id="auth.register.email" />
          </label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={(e) => handleChange(e)}
            className="form-control"
            placeholder="email@example.com"
            required
          />
        </div>
        <div className={`${styles.inputHolder} ${styles.passwordHolder}`}>
          <label>
            <FormattedMessage id="auth.register.password" />
          </label>
          <input
            type={show ? "text" : "password"}
            className="form-control"
            id="password"
            value={formData.password}
            placeholder="Password"
            onChange={(e) => handleChange(e)}
            required
          />
          <span
            onClick={() => setShow(!show)}
            className={styles.togglePassword}
          ></span>
        </div>
        {logInError && (
          <p>
            <IonText className="mb-1" color="danger">
              {logInError}
            </IonText>
          </p>
        )}

        <button
          type="submit"
          className="btn btn-visito-primary w-100 py-8 mb-3 mt-4 rounded-2"
        >
          {loading ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">
                <FormattedMessage id="common.loading" />
                ...
              </span>
            </Spinner>
          ) : (
            <FormattedMessage id="auth.register.register" />
          )}
        </button>
        <div className="color-step-500 fs-2">
          <FormattedMessage id="auth.register.continuing" />{" "}
          <a
            href="https://www.visitoai.com/en/terms"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="auth.register.loginTerms" />
          </a>{" "}
          <FormattedMessage id="common.and" />{" "}
          <a
            href="https://www.visitoai.com/en/privacy"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="auth.register.loginPrivacy" />
          </a>
        </div>

        <div className="text-center mt-4">
          <div className="color-step-500">
            <FormattedMessage id="auth.register.loginLabel" />
          </div>
          <div>
            <Link to={registerLink}>
              <FormattedMessage id="auth.register.login" />
            </Link>
          </div>
        </div>
      </form>
    </>
  );

  const error = (
    <>
      <IonText className="mb-1" color="danger">
        {CBError}
      </IonText>
      <hr />
      <div className="color-step-500 text-center">
        <Link to="/auth/login">
          <FormattedMessage id="auth.register.login" />
        </Link>{" "}
        <FormattedMessage id="common.or" />{" "}
        <Link to="/auth/register">
          <FormattedMessage id="auth.login.register" />
        </Link>
      </div>
    </>
  );

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className={styles.authContainer}>
          <div className={styles.authHolder}>
            <div className={styles.authPanel}>
              <h1>
                {" "}
                <FormattedMessage id="auth.register.titleCloudbeds" />
              </h1>
              {CBError ? error : null}
              {CBLoading && <SkeletonText rows={6} />}
              {CBSuccess.code ? form : null}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({}))(Register);
