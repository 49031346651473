import { useEffect, useState } from "react";
import { Location } from "history";
import {
  IonHeader,
  IonToolbar,
  isPlatform,
  IonPage,
  IonContent,
  IonTitle,
  useIonToast,
  IonButtons,
  IonBackButton,
  IonList,
} from "@ionic/react";

// Reac int
import { FormattedMessage, useIntl } from "react-intl";

// Types
import styles from "./styles.module.css";

// Styles
import headerStyles from "../../theme/header.module.css";

// Api
import Api from "../../api/integrations";

// Components
import SkeletonLoader from "../SkeletonLoader";
import IntegrationItem from "./integration-item";
import IntegrationDetails from "./integration-details";
import NewCloudbedsConnection from "./new-cloudbeds-connection";
import NewDbConnection from "./new-database-connection";

// Other
import { deserialize } from "../../utils/serialize";
import { loadScript } from "../../utils/script";
import { Integration, Connection, apiResult } from "../../interfaces";
import { integrationList } from "./constants";

const isMobile = isPlatform("android") || isPlatform("ios");

interface AuthResponse {
  accessToken?: string;
  code?: string;
}

const Header: React.FC = () => {
  return (
    <div>
      <h1 className={headerStyles.pageTitle}>
        <FormattedMessage id="integrations.pageTitle" />
      </h1>
      <h3 className={headerStyles.pageDescription}>
        <FormattedMessage id="integrations.pageDescription" />
      </h3>
    </div>
  );
};

const MobileHeader: React.FC = () => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="integrations.pageTitle" />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="integrations.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const MobileTitle = ({ backButton }: { backButton: string }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref={`/settings`} text={backButton} />
        </IonButtons>
        <IonTitle>
          <FormattedMessage id="nav.integrations" />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

const integrationBody: Integration = {
  cssLogo: "",
  connected: false,
  accounts: [],
  key: "",
  type: "",
  fields: [],
};

const Integrations: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [show, showModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [present] = useIonToast();
  const intl = useIntl();
  const [selectedIntegration, setSelectedIntegration] =
    useState<Integration>(integrationBody);
  const [list, setList] = useState<Integration[]>(integrationList);

  // Cloubeds New Connection
  const [showCloudbeds, setShowCloudbeds] = useState(false);
  const [cloudbedsCode, setCloudbedsCode] = useState("");
  const [cloudbedsError, setCloudbedsError] = useState("");
  const [cloudbedsLoading, setCloudbedsLoading] = useState(false);

  // General Database Connection
  const [showDbConnection, setShowDbConnection] = useState(false);
  const [dbError, setDbError] = useState("");
  const [dbLoading, setDbLoading] = useState(false);

  useEffect(() => {
    getIntegrations();
    loadScript("https://connect.facebook.net/en_US/sdk.js", () => {
      window.FB.init({
        appId: "516908693723488",
        cookie: true,
        xfbml: true,
        version: "v21.0",
      });
    });
  }, []);

  useEffect(() => {
    const { search } = props.location;
    const params = deserialize(search);
    const { code } = params;
    if (code) {
      setCloudbedsCode(code);
      setShowCloudbeds(true);
    }
  }, [props.location.search]);

  const presentError = ({ message }: { message: string }) => {
    present({
      message,
      duration: 5500,
      color: "danger",
      position: "top",
    });
  };

  const presentSucess = ({ message }: { message: string }) => {
    present({
      message,
      duration: 6000,
      color: "success",
      position: "top",
    });
  };

  const getIntegrations = async () => {
    setLoading(true);
    const { data, err } = await Api.list();
    setLoading(false);
    if (data) {
      const { integrations } = data;
      const newList = list
        .map((i) => {
          const backend = integrations.filter(
            (j: Integration) => j.key === i.key
          );
          return {
            ...i,
            connected: backend.length ? true : false,
            accounts: backend.map((b: Connection) => ({
              name: b.name,
              id: b.id,
              active: b.active,
            })),
          };
        })
        .sort((a, b) =>
          a.connected === b.connected ? 0 : a.connected ? -1 : 1
        );
      setList(newList);
      if (selectedIntegration.key) {
        const selected = newList.find((i) => i.key === selectedIntegration.key);
        setSelectedIntegration(selected || integrationBody);
      }
    }

    if (err) {
      return presentError({
        message:
          err[intl.locale] || intl.formatMessage({ id: "common.tryItLater" }),
      });
    }
  };

  const acceptNewCloudbedsIntegration = async (code: string) => {
    setCloudbedsLoading(true);
    setCloudbedsError("");
    const { data, err } = await Api.cloudbedsCode(code);
    if (err) {
      setCloudbedsError(err.message);
    }
    if (data) {
      onWillDismiss();
      getIntegrations();
      presentSucess({
        message: intl.formatMessage({
          id: "integrations.completedCloudbedsNewIntegration",
        }),
      });
    }
    setCloudbedsLoading(false);
  };

  const onWillDismiss = () => {
    showModal(false);
    setShowCloudbeds(false);
    setShowDbConnection(false);
  };

  const selectIntegration = ({ integration }: { integration: Integration }) => {
    setSelectedIntegration(integration);
    showModal(true);
  };

  const createChannels = async ({
    auth,
    config,
  }: {
    auth: AuthResponse;
    config: apiResult;
  }) => {
    setLoading(true);
    const { code, accessToken } = auth;
    const { type, platform } = config;
    const { data, err } = await Api.connectMeta({
      meta: {
        token: type === "user" ? accessToken : code,
        type,
        platform,
      },
    });
    if (err) {
      presentError({
        message:
          err[intl.locale] || intl.formatMessage({ id: "common.tryItLater" }),
      });
    }
    if (data) {
      presentSucess({
        message: intl.formatMessage({
          id: "integrations.completedDbIntegration",
        }),
      });
    }
    getIntegrations();
  };

  const toggleConnection = async ({
    connection,
    integration,
    action,
  }: {
    connection: Connection;
    integration: Integration;
    action: object;
  }) => {
    const updatedList = list.map((item) => {
      if (item.key !== integration.key) return item;
      const updatedAccounts = item.accounts.map((account) =>
        account.id === connection.id ? { ...account, ...action } : account
      );
      return { ...item, accounts: updatedAccounts };
    });

    setList(updatedList);

    const { err, data } = await Api.toggle({ connection, integration, action });

    if (data) {
      getIntegrations();
    }
    if (err) {
      console.log(err);
    }
  };

  const addDbIntegration = async ({
    integration,
    form,
  }: {
    integration: Integration;
    form: object;
  }) => {
    setDbLoading(true);
    const { data, err } = await Api.addDbIntegration({ integration, form });
    setDbLoading(false);
    if (data) {
      onWillDismiss();
      getIntegrations();
      presentSucess({
        message: intl.formatMessage({
          id: "integrations.completedDbIntegration",
        }),
      });
    }
    if (err) {
      console.log(err);
      presentError({
        message:
          err[intl.locale] || intl.formatMessage({ id: "common.tryItLater" }),
      });
      setDbError(err);
    }
  };

  const connectIntegration = async ({
    integration,
  }: {
    integration: Integration;
  }) => {
    switch (integration.type) {
      case "channel":
        switch (integration.key) {
          case "fm":
            handleMetaNewConnection({
              id: "542214705425786",
              type: "system",
              platform: "fm",
            });
            break;
          case "wa":
            handleMetaNewConnection({
              id: "1389334938656203",
              type: "system",
              platform: "wa",
            });
            break;
          case "ig":
            handleMetaNewConnection({
              id: "1907583366390905",
              type: "system",
              platform: "ig",
            });
            break;
          case "voice":
            setSelectedIntegration(integration);
            setShowDbConnection(true);
            break;
        }
        break;
      case "location":
        switch (integration.key) {
          case "cb":
            window.open(
              "https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=visitoai_hcPbRdNgJUAyMwI58iOf2kHF&redirect_uri=https%3A%2F%2Fapp.visitoai.com%2Fdoor%3Fpartner%3Dcloudbeds&response_type=code&scope=read%3Aadjustment+read%3Aitem+read%3Aroomblock+read%3AallotmentBlock+read%3Acommunication+read%3Acurrency+read%3AcustomFields+read%3Adashboard+read%3Aguest+read%3Ahotel+read%3Apayment+read%3Arate+read%3Areservation+write%3Areservation+read%3Aroom+read%3AtaxesAndFees+read%3Auser",
              "_blank"
            );
            break;
          case "mirai":
            window.open(
              "https://wa.me/14154235220?text=I want to connect Mirai to Visito AI",
              "_blank"
            );
            break;
          case "sm":
          case "gs":
            setSelectedIntegration(integration);
            setShowDbConnection(true);
            break;
        }
    }
  };

  const handleMetaNewConnection = (config: apiResult) => {
    window.FB.login(
      (response: apiResult) => {
        console.log(response);
        if (
          response.status === "connected" ||
          (config.type === "system" &&
            response.status === "unknown" &&
            response.authResponse)
        ) {
          console.log("getting token after login", response.authResponse);
          createChannels({ auth: response.authResponse, config });
        }
      },
      {
        config_id: config.id,
        response_type: config.type === "user" ? "token" : "code",
        override_default_response_type: true,
      }
    );
  };

  return (
    <IonPage id="main-content">
      {isMobile && (
        <MobileTitle backButton={intl.formatMessage({ id: "common.back" })} />
      )}
      <IonContent
        {...(isMobile
          ? { fullscreen: true, color: "light", className: "ion-padding" }
          : {})}
      >
        <div className={isMobile ? "view-wrapper" : "page-wrapper container"}>
          {isMobile ? (
            <>
              <div className="view-pane">
                <MobileHeader />
              </div>
              {loading ? (
                <SkeletonLoader rows={4} />
              ) : (
                <IonList inset={true}>
                  {list.map((integration) => (
                    <IntegrationItem
                      key={integration.key}
                      integration={integration}
                      onSelect={() => selectIntegration({ integration })}
                      onConnect={() => connectIntegration({ integration })}
                      isMobile={isMobile}
                    />
                  ))}
                </IonList>
              )}
            </>
          ) : (
            <>
              <Header />
              {loading ? (
                <SkeletonLoader rows={4} />
              ) : (
                <div className={styles.integrationContainer}>
                  {list.map((integration) => (
                    <div
                      key={integration.key}
                      className={styles.integrationItem}
                    >
                      <IntegrationItem
                        integration={integration}
                        onSelect={() => selectIntegration({ integration })}
                        onConnect={() => connectIntegration({ integration })}
                        isMobile={isMobile}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>

        {/* Modals */}
        <NewCloudbedsConnection
          isMobile={isMobile}
          isOpen={showCloudbeds}
          create={() => acceptNewCloudbedsIntegration(cloudbedsCode)}
          error={cloudbedsError}
          loading={cloudbedsLoading}
          onWillDismiss={onWillDismiss}
        />

        <NewDbConnection
          isOpen={showDbConnection}
          onCreate={(form: object) =>
            addDbIntegration({ integration: selectedIntegration, form })
          }
          error={dbError}
          loading={dbLoading}
          onWillDismiss={onWillDismiss}
          integration={selectedIntegration}
          isMobile={isMobile}
        />

        <IntegrationDetails
          isMobile={isMobile}
          isOpen={show}
          onWillDismiss={onWillDismiss}
          integration={selectedIntegration}
          onReconnect={() =>
            connectIntegration({ integration: selectedIntegration })
          }
          toggle={({
            connection,
            action,
          }: {
            connection: Connection;
            action: object;
          }) =>
            toggleConnection({
              connection,
              integration: selectedIntegration,
              action,
            })
          }
        />
        {/* end Modals */}
        {/* Meta Scripts */}
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  location: Location;
}

export default Integrations;
