import { connect } from "react-redux";
import { Form } from "react-bootstrap";
// User
import { User } from "../../../../interfaces";

import Auth from "../../../../api/auth";

import { setLanguage } from "../../../../actions/app";

import { FormattedMessage } from "react-intl";

const Language: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const switchLanguage = async (language: string) => {
    const { err } = await Auth.language({ language });
    if (err) {
      console.log(err);
    }
    props.dispatch(setLanguage(language));
  };

  return (
    <div className="settings-presentation">
      <h4>
        <FormattedMessage id="language.title" />
      </h4>
      <p className="color-step-700 fs-3">
        <FormattedMessage id="language.subTitle" />
      </p>
      <hr />
      <label className="fs-3 fw-bold mb-2">
        <FormattedMessage id="language.selectorLabel" />
      </label>
      <Form.Select
        aria-label="Select"
        onChange={(e) => switchLanguage(e.target.value)}
        value={props.user.language}
      >
        <option value="en">
          <FormattedMessage id="language.english" />
        </option>
        <option value="es">
          <FormattedMessage id="language.spanish" />
        </option>
        <option value="pt">
          <FormattedMessage id="language.portuguese" />
        </option>
      </Form.Select>
    </div>
  );
};

interface ILayoutProps {
  dispatch: Function;
  user: User;
}

export default connect((props: any) => ({}))(Language);
