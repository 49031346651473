import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type UrlWithStatus = {
  url: string;
  completed: boolean;
  error: boolean;
  embeddingId?: string;
  _id?: string;
};

export type GenericEmbedding = {
  id?: string;
  text: string;
}

export type Embedding = {
  urls?: UrlWithStatus[];
  progress?: number;
  textContext?: GenericEmbedding;
  providerContext?: GenericEmbedding;
  isOptimizing?: boolean;
  isScanning?: boolean;
  failed?: boolean;
};

export type EmbeddingState = {
  embedding: Embedding
}

interface EmbeddingsState {
  embedding: Embedding
  setEmbedding: (embedding: Embedding) => void;
  reset: () => void;
};

const embeddingsInitialState: EmbeddingState = {
  embedding: {
    urls: undefined,
    progress: 0,
    textContext: undefined,
    providerContext: undefined,
    isOptimizing: false,
    isScanning: false,
    failed: undefined,
  }
};

const useEmbeddingsStore = create(
  devtools<EmbeddingsState>(
    (set, get) => ({
      ...embeddingsInitialState,
      setEmbedding: (embedding) => {
        const embeddingState = get().embedding;
        return set({ embedding: { ...embeddingState, ...embedding } })
      },
      reset: () => set({ ...embeddingsInitialState })
    }),
    { name: "useEmbeddingsStore" }
  ),
);

export const useEmbeddingsData = () =>
  useEmbeddingsStore((state) => ({
    embedding: state.embedding
  }));

export const useEmbeddingsActions = () =>
  useEmbeddingsStore((state) => ({
    setEmbedding: state.setEmbedding,
    reset: state.reset,
  }))

export default useEmbeddingsStore;

