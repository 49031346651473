import { useState, useEffect } from "react";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
} from "@ionic/react";
import { useQuery } from "@tanstack/react-query";

import SkeletonText from "../../../components/SkeletonText";

// Api
import AiApi from "../../../api/ai";

import AiTextContext from "../../../components/ai/text-context";
import { GenericEmbedding } from "../../../store/embeddings";

type EmbeddingsInitialState = {
  textContext?: GenericEmbedding;
  providerContext?: GenericEmbedding;
}

function AiEmbeddings() {
  const [embeddings, setEmbeddings] = useState<EmbeddingsInitialState>({
    textContext: undefined,
    providerContext: undefined,
  });

  const {
    data: { data: aiData } = {},
    isLoading: isGettingAi,
    isRefetching: isRefetchingAi,
  } = useQuery({
    queryFn: () => AiApi.getAiSettings(),
    queryKey: ["getAiSettings"],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (aiData?.aiSettings?.embeddings && !embeddings?.textContext && !embeddings?.providerContext) {
      const oldContext = aiData.aiSettings?.context?.general?.text;

      const { embeddings } = aiData.aiSettings;
      const context = embeddings?.find((emb: any) => emb?.type === "text");
      const providerContext = embeddings?.find((emb: any) => emb?.type === "provider");

      setEmbeddings((prevState) => ({
        ...prevState,
        textContext: context?.embededContent || oldContext ? { id: context?._id || undefined, text: context?.embededContent || oldContext } : undefined,
        providerContext: providerContext?.embededContent ? { id: providerContext?._id, text: providerContext?.embededContent } : undefined,
        embeddingId: context?._id,
      }));
    }
  }, [aiData]);

  const loading = isGettingAi || isRefetchingAi;

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/ai-settings">
              {" "}
              AI Settings
            </IonBackButton>
          </IonButtons>
          <IonTitle>Text</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div>
          {loading && (
            <div className="h-75 w-100 flex flex-column p-4">
              <SkeletonText rows={17} />
            </div>
         )}
         {aiData?.aiSettings?.context?.general?.limit && embeddings?.textContext && (
            <AiTextContext
              limit={aiData?.aiSettings?.context?.general?.limit || 0}
              textContext={embeddings?.textContext}
              // providerContext={embeddings?.providerContext}
              isMobile
            />
         )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AiEmbeddings;
