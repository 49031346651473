import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isPlatform, useIonToast } from "@ionic/react";
import { useEffect, useRef } from "react";
import { Capacitor } from "@capacitor/core";

import { io, Socket } from "socket.io-client";

// Config
import config from "../../config";

// Interfaces
import {
  User,
  Event,
  DefaultEventsMap,
  Request,
  Settings,
  CallEvent,
} from "../../interfaces";

// Actions
import { setEvent, setSocket, setLoaded } from "../../actions/events";
import {
  setSettings,
  setAppUserCompany,
  setAppPushToken,
} from "../../actions/app";
import { updateContactsDb } from "../../actions/contacts";

// API
import SettingsApi from "../../api/settings";
import AuthApi from "../../api/auth";

//Pages
import MobileHome from "./MobileHome";
import DesktopHome from "./DesktopHome";

import Nots from "../../components/DesktopNotification";
import Push from "../../components/PushNotification";
import GA from "../../components/GoogleAnalytics";
import { usePlaygroundActions } from "../../store/playground";
import { setCompany } from "../../utils/localStorage";
import { setCallServerEvent } from "../../actions/call";

const version = config.VERSION;

const mobile = isPlatform("android") || isPlatform("ios");

const HOCDashboard: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const history = useHistory();
  const { user, logOut, events, requests, refreshUser, pushToken, callEvent } =
    props;
  const socketRef = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | null>(
    null
  );
  const [present] = useIonToast();
  const { createWebId } = usePlaygroundActions();
  const getSettings = async () => {
    const { data, err } = await SettingsApi.get();
    if (data) {
      props.dispatch(setSettings(data.settings));
    }
    if (err) {
      props.dispatch(setSettings({}));
    }
  };

  const switchCompany = async (companyId: string) => {
    const { data, err } = await AuthApi.switchCompany({ companyId });
    if (data) {
      const { company } = data.user;
      await setCompany({ company });
      window.location.reload();
    }
    if (err) {
      return present({
        message: "Something went wrong",
        duration: 5000,
        color: "danger",
        position: "top",
      });
    }
  };

  useEffect(() => {
    getSettings();
    const query = { ...user, ...version, mobile };
    const socket = io(config.API_ROOT, {
      query,
    });

    socketRef.current = socket;

    const heartbeatTimer = setInterval(() => {
      socket.emit("heartBeat", query);
    }, 60000);

    socket.on("connect", () => {
      props.dispatch(setSocket({ connected: true }));
    });

    socket.on("disconnect", () => {
      props.dispatch(setSocket({ connected: false }));
    });

    socket.on("not-escalation", (message) => {
      const { data, timeStamp, companyId } = message;
      props.dispatch(
        setEvent({ timeStamp, companyId, type: "not-escalation", data })
      );
    });

    socket.on("not-new-message", (message) => {
      const { data, timeStamp, companyId } = message;
      props.dispatch(
        setEvent({ timeStamp, companyId, type: "not-new-message", data })
      );
    });

    socket.on("new-message", (message) => {
      const { data, timeStamp, companyId } = message;
      if (message.data) {
        props.dispatch(
          setEvent({ timeStamp, companyId, type: "new-message", data: [] })
        );
        props.dispatch(updateContactsDb(data));
      }
    });

    socket.on("company-message", (message) => {
      const { data, timeStamp, companyId } = message;
      switch (data.task) {
        case "settings":
          props.dispatch(setSettings(data.message));
          break;
        default:
          props.dispatch(
            setEvent({ timeStamp, companyId, type: "company-message", data })
          );
      }
    });

    socket.on("call-event", (message) => {
      props.dispatch(setCallServerEvent(message));
    });

    socket.on("user-message", (message) => {
      const { data, timeStamp, userId } = message;
      props.dispatch(
        setEvent({ timeStamp, userId, type: "user-message", data })
      );
    });

    socket.on("updateBrowser", (message) => {
      if (Capacitor.isNativePlatform()) {
        history.push(`/download-new-version`);
      } else {
        window.location.reload();
      }
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("new-message");
      socket.off("initial");
      socket.off("cloudbeds-setup");
      clearInterval(heartbeatTimer);
      socket.disconnect();
    };
  }, [props.dispatch]);

  useEffect(() => {
    const { companyId, userId, data } = events.event;
    if (events.loading && companyId) {
      props.dispatch(setLoaded());
    }
    if (userId && data && data.task === "logout") {
      window.location.reload();
    }
  }, [events]);

  useEffect(() => {
    const { contactId, filter } = requests.request;
    if (contactId || filter) {
      console.log("emiting.....");
      const emitRequest = () => {
        if (socketRef.current && socketRef.current.connected) {
          socketRef.current.emit("requests", {
            request: requests.request,
            user: user,
          });
        } else {
          console.log("Socket not connected, waiting...");
          socketRef?.current?.once("connect", emitRequest);
        }
      };
      emitRequest();
      return () => {
        if (socketRef.current) {
          socketRef.current.off("connect", emitRequest);
        }
      };
    }
  }, [requests]);

  useEffect(() => {
    const { eventName } = callEvent;
    if (socketRef.current && socketRef.current.connected && eventName) {
      console.log("emeting call event...");
      socketRef.current.emit(eventName, {
        callEvent,
        user: user,
      });
    }
  }, [callEvent]);

  // For creating the web id -> playground
  useEffect(() => {
    createWebId(user.company.id);
  }, []);

  const pushEvent = async (event: Event) => {
    switch (event.type) {
      case "chat":
        mobile && event.data && history.push(`/chat/${event.data.id}`);
        !mobile && event.data && history.push(`/chats?chat=${event.data.id}`);
        break;
    }
  };

  return mobile ? (
    <>
      <GA />
      <Push
        request={Capacitor.isNativePlatform()}
        setEvent={pushEvent}
        setPushToken={(token: string) =>
          props.dispatch(setAppPushToken({ token }))
        }
        pushToken={pushToken}
      />
      <Route
        path="/"
        render={(props) => (
          <MobileHome
            {...props}
            logOut={logOut}
            refreshUser={refreshUser}
            user={user}
            event={events.event}
            onRequestSettings={() => getSettings()}
            switchCompany={switchCompany}
          />
        )}
      />
    </>
  ) : (
    <>
      <GA />
      <Nots event={events.event} request={!mobile} setEvent={pushEvent} />
      <Route
        path="/"
        render={(props) => (
          <DesktopHome
            {...props}
            logOut={logOut}
            refreshUser={refreshUser}
            switchCompany={switchCompany}
            user={user}
            event={events.event}
            onRequestSettings={() => getSettings()}
          />
        )}
      />
    </>
  );
};

interface ILayoutProps {
  user: User;
  logOut: Function;
  refreshUser: Function;
  dispatch: Function;
  settings: Settings;
  events: {
    loading: boolean;
    event: Event;
  };
  requests: {
    request: Request;
  };
  pushToken: String;
  callEvent: CallEvent;
}

export default connect((props: any) => ({
  events: props.events,
  requests: props.requests,
  settings: props.app.settings,
  pushToken: props.app.auth.pushToken,
  callEvent: props.calls.callEvent,
}))(HOCDashboard);
