import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  useIonToast,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";

import { IconPencil } from "@tabler/icons-react";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  tablePlugin,
  ListsToggle,
  InsertTable,
  linkPlugin,
  frontmatterPlugin,
  BlockTypeSelect,
  imagePlugin,
  MDXEditorMethods,
} from "@mdxeditor/editor";

import AiApi from "../../../api/ai";
import CrawlWebsite from "../website";

import styles from "./styles.module.css";
import { GenericEmbedding } from "../../../store/embeddings";

type AiContext = {
  textContext?: GenericEmbedding;
  providerContext?: GenericEmbedding;
};

type AiContextProps = AiContext & {
  limit: number;
  isMobile?: boolean;
};

function AiTextContext({
  limit,
  isMobile = false,
  textContext,
  providerContext,
}: AiContextProps) {
  const [edit, setEdit] = React.useState(false);
  const [currentSegment, setCurrentSegment] = React.useState<string>();
  const [currentEmbedding, setCurrentEmbedding] = React.useState<
    GenericEmbedding | undefined
  >(textContext);
  const ref = React.useRef<MDXEditorMethods>(null);

  const [present] = useIonToast();

  const intl = useIntl();

  const queryClient = useQueryClient();

  const { mutate: mutateUpdateSettings, isPending: isUpdatingSettings } =
    useMutation({
      mutationFn: AiApi.putAiSettingsByFieldV2,
      onSuccess: async () => {
        setEdit(false);
        await queryClient.invalidateQueries({
          queryKey: ["getAiSettings"],
        });
        return present({
          message: intl.formatMessage({ id: "aiUpdateSucces" }),
          duration: 4000,
          color: "light",
          position: "top",
          cssClass: "success-toast",
        });
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
        });
      },
    });

  const onChangeEditor = (content: string) => {
    setCurrentEmbedding((prevState) => ({ ...prevState, text: content }));
  };

  const onSave = () => {
    if (currentEmbedding?.text && currentEmbedding?.text?.length > limit) {
      return present({
        message: intl.formatMessage({ id: "aiExceededLength" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    }

    return mutateUpdateSettings({
      field: "general",
      value: currentEmbedding?.text,
      embeddingId: currentEmbedding?.id,
    });
  };

  const onBeforeSetSegment = (segment: string) => {
    setEdit(false);
    const isProvider = segment === "providerContext";
    setCurrentSegment(isProvider ? "providerContext" : "textContext");
    setCurrentEmbedding(isProvider ? providerContext : textContext);
    ref.current?.setMarkdown(
      isProvider ? providerContext?.text || "" : textContext?.text || ""
    );
  };

  React.useEffect(() => {
    if (!textContext && providerContext) {
      setCurrentSegment("providerContext");
      setCurrentEmbedding(providerContext);
      ref.current?.setMarkdown(providerContext.text);
    } else {
      setCurrentSegment("textContext");
      setCurrentEmbedding(textContext);
      ref.current?.setMarkdown(textContext?.text || "");
    }
  }, [textContext, providerContext]);

  React.useEffect(() => {
    return () => {
      queryClient.invalidateQueries({
        queryKey: ["getAiSettings"],
      });
    };
  }, []);

  return (
    <div className={styles.editorContainer}>
      {providerContext && (
        <>
          <IonSegment
            className="context-segment py-1"
            value={currentSegment}
            mode="ios"
          >
            <IonSegmentButton
              className="context-button"
              value="textContext"
              onClick={() => onBeforeSetSegment("textContext")}
            >
              <IonLabel>
                <FormattedMessage id="aiGeneralContext" />
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              className="context-button"
              value="providerContext"
              onClick={() => onBeforeSetSegment("providerContext")}
            >
              <IonLabel>
                <FormattedMessage id="aiProviderContext" />
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className={styles.divider} />
        </>
      )}

      {providerContext &&
      currentSegment === "textContext" &&
      !currentEmbedding?.text ? (
        <CrawlWebsite variant="full" />
      ) : (
        <MDXEditor
          ref={ref}
          markdown={currentEmbedding?.text || ""}
          onChange={onChangeEditor}
          readOnly={!edit}
          contentEditableClassName={styles.editorFixedHeight}
          plugins={[
            headingsPlugin(),
            tablePlugin(),
            listsPlugin(),
            quotePlugin(),
            linkPlugin(),
            thematicBreakPlugin(),
            frontmatterPlugin(),
            markdownShortcutPlugin(),
            imagePlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  {!isMobile && edit && (
                    <>
                      <BlockTypeSelect />
                      <div id="styles">
                        <BoldItalicUnderlineToggles />
                      </div>
                      <div id="list">
                        <ListsToggle />
                      </div>
                      <InsertTable />
                    </>
                  )}
                  <div className="ml-auto flex gap-3 align-items-center">
                    {edit ? (
                      <>
                        <p className="mb-0">
                          {`${currentEmbedding?.text?.length || 0} / ${limit}`}
                        </p>
                        {isUpdatingSettings && !isMobile && (
                          <p className="mb-0 fw-semibold">
                            <FormattedMessage id="aiSettingsSaving" />
                          </p>
                        )}
                        <button
                          className="btn btn-visito-light btn-sm"
                          onClick={() => setEdit(false)}
                          disabled={isUpdatingSettings}
                        >
                          <FormattedMessage id="common.cancel" />
                        </button>
                        <button
                          className="btn btn-visito-primary btn-sm"
                          onClick={onSave}
                          disabled={isUpdatingSettings}
                        >
                          <FormattedMessage id="common.save" />
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn btn-visito-primary btn-flex btn-sm"
                        onClick={() => setEdit(true)}
                      >
                        <IconPencil size={14} />
                        <FormattedMessage id="aiUpdateCta" />
                      </button>
                    )}
                  </div>
                </>
              ),
            }),
          ]}
        />
      )}
    </div>
  );
}

export default AiTextContext;
