import NavDropdown from "react-bootstrap/NavDropdown";

type CompaniesDropdownProps = {
  currentCompany: string;
  variant?: "default" | "light";
  companies: { id: string; name: string }[];
  onSelectCompany: (id: string) => void;
}

export default function CompaniesDropdown({ currentCompany, companies, onSelectCompany, variant = "default" }: CompaniesDropdownProps) {
  const variantClass = variant && variant === "light" ? "light-account-switcher" : "account-switcher";

  return (
    <NavDropdown
      title={currentCompany}
      className={variantClass}
    >
      {companies.map((c) => (
        <NavDropdown.Item onClick={() => onSelectCompany(c.id)} key={c.id}>
          {c.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  )
}
