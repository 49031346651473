import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Location } from "history";
import { IonRouterOutlet, IonContent } from "@ionic/react";

// Interfaces
import { User, Event, Settings } from "../../interfaces";

// Components
import NavBar from "../../components/NavBar";
import NavBanner from "../../components/NavBanner";

import Outbound from "../../components/outbound";
import OutboundLogs from "../../components/outbound/logs/desktop";
import Home from "../../components/home";
import Crm from "../../components/crm";
import CrmCampaigns from "../../components/crm/campaigns";
import CrmLeads from "../../components/crm/leads";

// Pages
import AiSettings from "./desktop/AiSettings";
import AiSettingsInstructions from "./desktop/AiSettingsInstructions";
import AiSettingsSystem from "./desktop/AiSettingsSystem";

import Chats from "./desktop/Chats";
//import Home from "./desktop/Home";
import SettingsPage from "./desktop/Settings";
import Integrations from "../../components/integrations";
import AiSettingsV2 from "../../components/ai";

import AccountNewInvitation from "../../components/account/accept-invitation";

const DesktopHome: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const {
    logOut,
    user,
    location,
    event,
    settings,
    onRequestSettings,
    switchCompany,
    refreshUser,
  } = props;
  return (
    <>
      <NavBanner settings={settings} mode={"desktop"} />
      <NavBar
        user={user}
        logOut={logOut}
        switchCompany={switchCompany}
        settings={settings}
        path={location.pathname}
      />

      <IonContent>
        <IonRouterOutlet>
          <Route
            exact
            path="/"
            render={() => <Home user={user} settings={settings} />}
          />
          <Route exact path="/chats" component={Chats} />
          <Route
            exact
            path="/outbound"
            render={() => <Outbound settings={settings} />}
          />
          <Route exact path="/integrations" component={Integrations} />
          <Route path="/outbound/:id" component={OutboundLogs} />
          <Route exact path="/crm" component={Crm} />
          <Route exact path="/crm/campaigns" component={CrmCampaigns} />
          <Route exact path="/crm/leads" component={CrmLeads} />
          <Route exact path="/ai-settings" render={() => <AiSettings />} />
          <Route path="/ai-settings/:type" component={AiSettingsInstructions} />
          <Route
            path="/ai"
            render={() => <AiSettingsV2 event={event} settings={settings} />}
          />
          <Route path="/ai-settings-system" component={AiSettingsSystem} />
          <Route
            exact
            path="/account/accept-invitation"
            render={(routeProps) => (
              <AccountNewInvitation
                {...routeProps}
                refreshUser={refreshUser}
                user={user}
                settings={settings}
              />
            )}
          />
          <Route
            path="/settings"
            render={() => (
              <SettingsPage
                user={user}
                path={location.pathname}
                settings={settings}
                onRequestSettings={onRequestSettings}
              />
            )}
          />
          <Route exact path="/cloudbeds" component={Integrations} />
        </IonRouterOutlet>
      </IonContent>
    </>
  );
};

interface ILayoutProps {
  logOut: Function;
  refreshUser: Function;
  switchCompany: Function;
  user: User;
  location: Location;
  event: Event;
  settings: Settings;
  onRequestSettings: Function;
}

export default connect((props: any) => ({
  settings: props.app.settings,
}))(DesktopHome);
