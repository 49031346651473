import { createReducer } from "@reduxjs/toolkit";
import {
  getTimeStampForContact,
  getTimeStampForMessage,
  getTimeStampForMessageGrouping,
} from "../utils/moment";
import { AsYouType } from "libphonenumber-js";
import getSvgs from "../utils/messageSvgs";
import {
  setContactsLoading,
  insertSentMessage,
  modifyEnabled,
  changeContactStatus,
  updateContactsDb,
  readMessages,
} from "../actions/contacts";

import config from "../config";

import {
  ContactsDBItem,
  Contact,
  apiResult,
  messageApi,
  ContactsDBObject,
} from "../interfaces";

const isDev = config.ENV === "staging" || config.ENV === "local";
const loading = true;
const contactsDb: ContactsDBItem[] = [];
const contactList: Contact[] = [];
const contactsDbObject: ContactsDBObject = {};
const escalatedCount = 0;

function howMany(messages: messageApi[], roles: Array<any>) {
  let lastTime = null;
  let lastMessage = null;
  let unread = 0;

  const count = messages.reduce((count, message) => {
    if (!message.isRead) {
      unread++;
    }
    if (roles.includes(message.role)) {
      count++;
    }
    lastTime = message.createAt;
    lastMessage = message.content;
    return count;
  }, 0);

  return {
    count,
    lastTime,
    lastMessage: lastMessage ? lastMessage : "",
    unread,
  };
}

function runChatList(contacts: ContactsDBObject) {
  let list = [];
  let escalated = 0;
  let manual = 0;
  for (const key in contacts) {
    if (contacts.hasOwnProperty(key)) {
      const value: ContactsDBItem = contacts[key];
      if (value.contact.count) {
        list.push(value.contact);
      }
      if (value.contact.escalated) {
        escalated++;
      }
      if (value.contact.manual) {
        manual++;
      }
    }
  }
  return {
    list: list.sort((a, b) => b.timestampNative - a.timestampNative),
    escalated,
    manual,
  };
}

const getContactType = (contact: apiResult) => {
  if (contact.igId) {
    return "ig";
  }
  if (contact.fmId) {
    return "fm";
  }
  if (contact.calryId) {
    return "ab";
  }
  if (contact.phoneNumberId) {
    return "wa";
  }
  if (contact.webId) {
    return "web";
  }
  if (contact.callId) {
    return "voice";
  }
  return "play";
};

function adaptItem(c: apiResult, properties: apiResult) {
  let escalated = false;
  let isConversion = false;
  const contact = {
    name: c.fullName,
    timestamp: getTimeStampForContact(properties.lastTime),
    timestampNative: new Date(properties.lastTime).getTime(),
    preview: properties.lastMessage,
    id: c._id,
    unread: properties.unread,
    enabled: c.enabled,
    manual: !c.enabled,
    optedOut: c.optedOut,
    phoneNumber: new AsYouType().input(`+${c.phoneNumber}`),
    status: c.status,
    escalated,
    blocked: c.blocked ? true : false,
    count: properties.count,
    type: getContactType(c),
    followers: c.meta ? c.meta.followerCount : 0,
    username: c.meta ? c.meta.username : "",
    isGuest: c.bookings?.length,
    isBook: c.ai ? c.ai.isBook || false : false,
    isManual: c.ai ? c.ai.isManual || false : false,
    isConversion,
  };

  const companies = c.companies.map((c: apiResult) => ({
    name: c.name,
    id: c._id,
  }));

  const messages = c.messages.map((m: apiResult) => ({
    id: m._id,
    content: m.content,
    right: m.role === "assistant" || m.role === "outbound",
    status: m.status,
    escalated: m.escalated || false,
    timestamp: getTimeStampForMessage(m.createAt),
    timestampGrouping: getTimeStampForMessageGrouping(m.createAt),
    svg: getSvgs(m.status),
    interactive: {
      flag: m.interactive ? true : false,
      data: m.interactive,
    },
    isManual: m.meta?.user ? true : false,
    error: m.meta?.error ? m.meta.error : "",
  }));

  const bookings = c.bookings.map((b: apiResult) => {
    if (b.conversion) isConversion = true;
    return {
      phoneNumber: b.phoneNumber,
      status: b.status,
      externalId: b.externalId,
      fullName: b.fullName,
      from: b.from,
      to: b.to,
      locationName: b.locationName,
      rooms: b.rooms,
    };
  });

  const escalations = c.escalations.map((e: apiResult) => {
    if (e.open && contact.type !== "play") escalated = true;
    return {
      type: e.type,
      open: e.open,
      details: e.details,
    };
  });

  return {
    contact: {
      ...contact,
      escalated,
      isConversion,
      companies,
    },
    messages,
    bookings,
    escalations,
    companies,
  };
}

export const Contacts = createReducer(
  { contactsDb, contactsDbObject, contactList, loading, escalatedCount },
  {
    [setContactsLoading.type]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    [updateContactsDb.type]: (state, action) => {
      console.log("received", action.payload.contacts.length);
      action.payload.contacts.forEach((c: apiResult) => {
        const properties = howMany(c.messages, ["user", "assistant"]);
        const contact = adaptItem(c, properties);
        state.contactsDbObject[contact.contact.id] = contact;
      });
      const { list, escalated } = runChatList(state.contactsDbObject);
      const filteredList = isDev
        ? list
        : list.filter((contact) => contact.type !== "play");
      state.contactList = filteredList;
      state.escalatedCount = escalated;
    },
    [insertSentMessage.type]: (state, action) => {},
    [modifyEnabled.type]: (state, action) => {},
    [changeContactStatus.type]: (state, action) => {},
    [readMessages.type]: (state, action) => {},
  }
);
